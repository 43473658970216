import React from 'react'

export default function Terms() {
  return (
    <div className='m-10'>
      <h1 className='text-2xl font-semibold'>Terms and Conditions</h1>
<p className='text-lg mt-4'>The customer agrees and acknowledges that use of the services offered by Royal Car is at the sole risk of the Customer. The liability of Royal Car is excluded to the fullest extent permitted by law. Customer can rent a vehicle under Royal Car platform for Tourism Purpose only.</p>

<p className='mt-5 font-bold text-lg'>Document</p>
<ul>
  
<li className='mt-4 mx-2'> a. Driving License and Adhaar Card issued by Authority or Institution.</li>

<li className='mx-2'> b. Local IDs/Students must carry their Job/College/Institution ID card for the Address proof.</li>

<li className='mx-2'>c. Without any Job/College ID, No local ID is eligible to rent a car under Royal Car Platform</li>

<li className='mx-2'> d. Mail your driving license, Adhaar Card Copy or other ID & live photo in support@Royal Car.in for pre-verification of your documents.</li>

<li className='mx-2'>e. In the absence of any Valid/required Documents the booking will be treated as cancelled and Rs. 500/- will be deducted from total amount paid alongwith cancellation charges according to Cancellation Policy.</li>
</ul>
<p className='mt-5 font-bold'> Age Requirement </p>
<ul>
<li>a. Minimum 21 years for normal vehicles.</li>

<li>b. Minimum 24 years for Premium Range vehicles such as BMW, Audi,Mercedes,Endeavour,Fortuner,etc.</li>
</ul>
<p>Over Speeding charges: (Exceeding 80kmph)</p>
<ul>
  <li>a. A penalty of Rs. 200/- shall be charged on each instance.</li>

  <li>b. An additional penalty of Rs. 500/- shall be charged for multiple instances of speed violation</li>
</ul>
<p className='mt-5 font-bold'>Refundable Security Money:</p>
<ul>

<li>a. ₹.2000/- for Hatchback/MPV/MUV/Sedan & Rs.3000/- for SUV’s.</li>

<li>b. ₹.10, 000/- For Premium Range Vehicles: (BMW, Audi, Mercedes).</li>

<li>c. Mode of Security Amount: Cash / Paytm / Gpay / PhonePe / Card Payments /Online</li>

<li>d. Security Money will be refund as per the Mode of Payment by the Customer within 24hrs of Trip completion.</li>

<li>e. If Any Violation of Terms & Conditions done by Customer then the Security Money will be Refunded/Settled within 24 Hours from the Drop Time in respective mode of Payment.</li>
</ul>
<p className='mt-5 font-bold'>Kilometers Limitation:</p>
<ul>
a. There is Unlimited Kms Offered to the Customer In the Normal range vehicles for booking for 24Hrs & above.

b. Restricted Regions : Any deroute from the shared destination/route and Prohibited districts of Malkangiri, Phulbani , Koraput , Similipal , if travelled will incur a penalty of Rs.5,000/- and no RSA will be applicable on booking.

c. Outside Odisha: Customer must inform in advance if planning to go Outside the Odisha State Border Before Booking .Outside State Km Limited up to 300Kms (One Side)24hrs and above & 150Kms (One Side)12hrs from Pick Up Location.
</ul>
<p className='mt-5 font-bold'>Prohibited Use:</p>
<ul>
<li>a. Customers are not allowed to carry arms, ammunitions banned drugs & mortgage. In addition, use of car for commercial activity such as product sell and promotion, and carry goods is strictly prohibited. In such cases, customers will be charged a penalty of Rs. 10000 alongwith legal convenience charges.</li>

<li>b. For any speed race or competition.</li>

<li>c. For the purpose of towing, pushing, marraige or propelling any trailer or any other vehicle.</li>

<li>d. For the primary business purpose of transporting people or operating a taxi service.</li>

<li>e. By any person who is under the influence of:-</li>

<ul>
  <li>i. alcohol or</li>

  <li>i. any drug or medication under the effects of which the operation of a vehicle is prohibited or not recommended</li>
</ul>
<li>f. Illegal Nature:- For the purpose of commission of any crime or other illegal or unlawful activity.</li>

<li>g. Only Renter is authorized to drive the vehicle. If any other person found driving the vehicle or Sub letting of vehicle then Security money will be forfeited of the renter.</li>

<li>h. Smoking, Drinking, Pets & Other Types of Drugs Not Allowed Inside The car. If Anything Found inside the car then it will be chargeable ₹.2000/-.</li>
</ul>
<p className='mt-5 font-bold'>Roadside Assistance:</p>
<ul>
<li>a. Royal Car makes reasonable best efforts to provide roadside assistance support in all cases. If, however, a User's need for Roadside Assistance results from a breach of these Terms and Conditions, the User may be charged for the full costs of the service.</li>
</ul>
<p className='mt-5 font-bold'>Fuel Policy / Tolls & Late Charges:</p>
<ul>
<li>a. If the Customer Exceeds the Time Limit without any information Before 06 Hours of expiry of drop time then it will be Chargeable as a fine of extra 150/Hr (Hatchbacks) , 200/Hr (Sedans) , 250/Hr (SUVs) Per Hour.
</li>
<li>
b. Extension Rules are Subject to availability of vehicles.
</li>
<li>c. The renter must return the vehicle at the same level of fuel as of during Pickup/Delivery. Refueling charges will be applicable till same level of fuel maintained upon drop-off. Additional Refueling and Fastag recharge will not be reimbursable under any circumstances of events.</li>
</ul>
<p className='mt-5 font-bold'>Insurance & Damage charges:</p>
<ul>
<li>a. The company will not be responsible for any damages during the travel, if any damage while travel. Renter should take care of the repair charges by their own up to ₹.25000/- In Normal range vehicles & ₹.75,000/- In Premium range Vehicles.</li>

<li>b. Insurance claim can be applicable for damage or repaired charges above ₹.25000/- For Normal vehicles & ₹.75,000/- for premium range Vehicles only. For any excess charges apart from claim amount, the renter will be liable to pay. The renter must bear the rental of vehicle for idle period estimated by Royal Car from the drop-off time till the vehicle is restored under the insurance claims on the base price as per booked.</li>

<li>c. While travel if any mechanical fault in the car, our company will do alternate arrangements without any cost.</li>
</ul>
<li>Vehicles registered with Royal Car may be continuously tracked by using advanced telematics for security reasons or for reasons deemed fit and proper by Royal Car. Any tampering with mechanical / electrical / telematics such may be penalized and legal action may be taken against the renter alongwith a fine of ₹.10000/-.</li>

<li>Royal Car shall be entitled to terminate any booking at any time without giving any reason or prior notice to the Customer.
</li>
<p className='mt-5 font-bold'>Home Delivery:</p>
<li>a. Airport Delivery is Free(Only toll charges applicable).</li>

<li>b. Home delivery is Free Including Pick up/Drop up within the radius of 10Km. Any Delivery/Pickup post the radius will be chargeable additionally.</li>

<p className='mt-5 font-bold'>Cancellation:</p>
<li>a. ₹.500/- Will be Deductable on the Cancellation of Booking before 24 Hours from the Pickup Time.</li>

<li>b. 50% of The Total amount of Rental will be refunded on the Cancellation of the Vehicle within 24 Hours from Pick Up time.</li>

<li>c. No Refunds on the Cancellation of Booking within 06 Hours From the pick up time.</li>

<li>d. ₹.500 will be Deductable on Swiping/Change of Vehicle.</li>

<li>e. If Customer Doesn’t Pick up the Vehicle within 06 Hours from the Pickup time without Information then the Booking will be treated as Cancelled & the amount will be refunded with the Deduction of Rs.1500/- from the Total Amount.</li>

<li>f. Cancellation refund amount will be credited within 07 Working Days from the Date of Cancellation..</li>

<p className='mt-5 font-bold'>Working Hours: 06:00 am -11:00 pm all Days</p>
    </div>
  )
}
