import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Link } from 'react-router-dom';

const Footer = () => {
  const containerStyle = {
    width: '100%',
    height: '250px'
  };

  const center = {
    lat: 20.2961, // Replace with the latitude of your specific location
    lng: 85.8245  // Replace with the longitude of your specific location
  };

  return (
    <footer className="bg-gray-800 text-slate-300 p-8 mt-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Contact Details */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p className='mt-2'>Royal Cars</p>
          <p className='mt-2'>Bhubaneswar, Odisha, India</p>
          <p className='mt-2' href='mailto:royalcarbbsr@gmail.com'>Support Email: royalcarbbsr@gmail.com</p>
          <p className='mt-2'>Phone: +91 9777824577</p>
          <p className='mt-2'>Road Side Assistance: +91 9078360902 </p>
          <p className='mt-2'>24x7 Support: +91 9078361291 </p>
        </div>
        <div>
            <h2 className='text-2xl font-semibold mb-4'>Links</h2>
            <ul>
            <Link to={'/'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>Home</li>
            </Link>
            <Link to={'/about'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>About Us</li>
            </Link>
            <Link to={'/pay'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>Pay Now</li>
            </Link>
                <Link to={'/terms'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>Terms & Conditions</li>
              </Link>
              <Link to={'/policy'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>Cancellations & Refunds</li>
              </Link>
              <Link to={'/privacy'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>Privacy</li>
              </Link>
              <Link to={'/blog'}>
                <li className='hover:cursor-pointer hover:text-green-500 m-3'>Blog</li>
              </Link>
            </ul>
        </div>
        
        {/* Google Map */}
        <div>
          <h2 className="text-2xl font-semibold mb-8">Find Us Here</h2>
          <iframe width="450" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=400&amp;height=200&amp;hl=en&amp;q=Royal%20car%20bhubaneswar%20+(Royal%20Car%20-%20Self%20drive%20car%20in%20Bhubaneshwar)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='https://www.easybooking.eu/'>Royal Car</a> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=a581e26f36279084844d86486edaf969ddc18d9f'></script>        </div>
      </div>
      
      <hr className='mt-4 w-full text-slate-400'/>
      <p className=' text-center m-2 text-slate-400'>Designed & Managed by - Modish Production</p>
      
    </footer>
  );
};

export default Footer;
