import React from 'react'
import { Route,Routes,BrowserRouter } from 'react-router-dom'
import Signup from './SignUp'
import Login from './Login'
export default function () {
  return (
    <>
    <div className='relative grid grid-cols-1 md:grid-cols-2 h-full m-8"'>
    
    <Login />
    
    </div>
    </>
  )
}
