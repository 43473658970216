import React,{useEffect,useState} from 'react'
import Logo from './img/Logo.png'
import background from './img/Background.png'
import Banner from './Banner'
import Display from './Display'
import InputForm from './InputForm'
import CardBox from './CardBox'
import TestimonialSection from './Testimonial'
import Steps from './Step'
import FeaturedCar from './FeaturedCar'
import call from './img/Call.png'
import WhatWeoffer from './WhatWeoffer'

export default function Maincomp() {
  const [login, setLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const userEmail = localStorage.getItem('userEmail'); // Assuming you store user email in localStorage
    //console.log(userEmail)
    const isAdminEmail = checkAdminEmail(userEmail); // Function to check if the user's email is an admin email
    setLogin(isLoggedIn);
    setIsAdmin(isAdminEmail);
    //handleNav();
    }, []); 
  
    const checkAdminEmail = (email) => {
      const adminEmails = ['alamshahnawaz1913@gmail.com','ramakantapradhan615@gmail.com','himanshu.fastinfo@gmail.com','royalcarbbsr@gmail.com']; // Add your admin email addresses here
      return adminEmails.includes(email);
    }
  //To block previous date.



  return (
    <>
      <div className='relative min-h-10 md:min-h-screen '>
        <Banner />
        <section className="relative grid grid-cols-1 md:grid-cols-2 gap-2" id="home">
          <Display />
          <div className='m-8'>
            <InputForm />
          </div>
        </section>
      </div>
      {/*<div className="min-h-screen bg-gray-100 flex items-center justify-center">
    <CardBox />
  </div>*/}
      <div className="container mx-auto text-center mt-4 relative">
        <h2 className=' text-5xl m-8 font-bold font-sans text-slate-600'>Best Self-Drive Car Rentals in Bhubaneswar - <span className='text-green-600'>Affordable Rates & Wide Selection</span> </h2>
        <p className='text-slate-500 text-xl m-4 text-justify'>Discover the convenience and 
        freedom of exploring Bhubaneswar with our top-rated self-drive car rentals. Whether 
        you're visiting for business or leisure, our service ensures you have the perfect 
        vehicle to meet your needs. From compact cars for city driving to spacious SUVs for 
        family trips, we have a wide selection of vehicles to choose from.</p>
        <h2 className=' text-5xl m-8 font-bold font-sans text-slate-600'>Discover Bhubaneswar with Self-Drive Car Rentals</h2>
        <p className='text-slate-500 text-xl m-6 text-justify'>
          Choosing hassle-free self-drive car rentals in Bhubaneswar unlocks the doors
          to a personalized travel experience where every journey is dictated by your
          whims and fancies. Picture yourself cruising along the scenic roads leading
          to the historic Lingaraj Temple or the tranquil banks of Bindusagar Lake,
          all at your own pace. This mode of travel not only offers unparalleled
          convenience but also ensures safety and comfort, especially in a time when
          control over your travel environment is paramount.
        </p>

        <h2 className='text-5xl m-8 font-bold font-sans text-slate-600'>Benefits of Our Self-Drive Car Rental Service</h2>
        <ul className='text-slate-500 text-xl m-4 text-justify'>
          <li className=' text-justify'>1. Wide Range of Vehicles: From compact cars perfect for city exploration to robust SUVs ideal for countryside adventures, we have a vehicle to suit every need.</li>
          <li>2. Affordable Rates: Enjoy competitive pricing without hidden charges. Our transparent pricing model allows you to budget your trip with confidence.</li>
          <li>3. Easy Booking Process: Our user-friendly online platform makes booking your car quick and simple. Select your preferred vehicle, choose your rental dates, and you're ready to go!</li>
          <li>4. Well-Maintained Cars: All our vehicles undergo regular maintenance and safety checks, ensuring a smooth and worry-free driving experience.</li>
          <li>5. 24/7 Customer Support: Our dedicated support team is available around the clock to assist you with any queries or issues during your rental period.</li>
        </ul>
      </div>
      <FeaturedCar />
      <WhatWeoffer />
      <TestimonialSection />
      <Steps />
      <div className="fixed bottom-5 right-4">
        <a
          href="tel:9777824577" // Phone No
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // Replace with your WhatsApp logo URL
            alt="WhatsApp"
            className="w-24 h-24" // Adjust the size as needed
          />
        </a>
      </div>

      <div className="fixed bottom-5 left-4">
        <a
          href="tel:9777824577" // WhatsApp number or link
          rel="noopener noreferrer"
          className="block w-24 h-24 items-center justify-center mx-auto mb-4"
        >
          <img
            src={call} // Replace with your WhatsApp logo URL
            alt="Phone"
            className="w-24 h-24" // Adjust the size as needed
          />
        </a>
      </div>

    </>
  )
}
