export const categories = [
    {
        id:1,
        name : "SUV",
        urlParamName : "SUV"

    },
    {
        id:2,
        name : "MUV",
        urlParamName : "MUV"

    },
    {
        id:3,
        name : "Hatchback",
        urlParamName : "hatchback"

    },
    {
        id:4,
        name : "Sedan",
        urlParamName : "sedan"

    },   
]

export const city =[
    {
        id:1,
        name : "Bhubaneshwar Airport Parking",
        urlParamName : "bhubaneshwar"

    },
]

export const deliveryType =[
    {
        id:1,
        name : "Parking Place",
        urlParamName : "parking place"

    },
    {
        id:2,
        name : "Home Delivery",
        urlParamName : "home delivery"

    },
]