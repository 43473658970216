import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { GiCarSeat, GiGearStickPattern } from 'react-icons/gi';
import { BsFuelPump } from 'react-icons/bs';
import Modal from './Modal';

export default function BlockCar() {
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [dropoffDate, setDropoffDate] = useState('');
  const [dropoffTime, setDropoffTime] = useState('');
  const [bookingConfirmation, setBookingConfirmation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const db = getFirestore();
        const carCollection = collection(db, 'cars');
        const snapshot = await getDocs(carCollection);
        const carData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCars(carData);
      } catch (error) {
        console.error('Error fetching cars:', error);
      }
    };
    fetchCars();
  }, []);

  const handleCarSelection = useCallback((carId, carName) => {
    setSelectedCar({ carId, carName });
    setIsModalOpen(true);
  }, []);

  const handleBooking = async () => {
    try {
      if (!pickupDate || !pickupTime || !dropoffDate || !dropoffTime) {
        alert("Please select pickup and dropoff dates and times.");
        return;
      }

      const bookingData = {
        carId: selectedCar.carId,
        pickupDate,
        pickupTime,
        dropoffDate,
        dropoffTime,
        carName: selectedCar.carName
      };

      await addDoc(collection(db, 'carBookings'), bookingData);
      setBookingConfirmation(`Booking confirmed for car ID: ${selectedCar.carId}`);
      setIsModalOpen(false);
      alert("Car blocked");
    } catch (error) {
      console.error('Error booking car:', error);
    }
  };

  return (
    <>
      <div className='flex flex-wrap space-x-4 space-y-4 justify-center gap-5 mt-9'>
        {cars.map(car => (
          <motion.div
            key={car.id}
            whileTap={{ scale: 0.8 }}
            className='group w-80 min-w-[94px] h-96 cursor-pointer rounded-lg drop-shadow-lg 
              flex flex-col items-center justify-center bg-slate-100 hover:bg-green-500 hover:scale-105 ease-in-out delay-75'
          >
            <p className='text-lg font-semibold'>{car.carName}</p>
            <img src={car.carPhoto} className='w-3/4' alt='car' />
            <p className='text-xs m-3'>Unlimited KMS without Fuel. Free Airport Delivery.</p>
            <div className='grid grid-cols-3 w-full mt-1'>
              <div className='flex flex-row items-center m-auto'>
                <GiCarSeat size={20} className='text-black' />
                <p className='text-xs m-1 font-semibold'>{car.seatCapacity}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                <BsFuelPump size={20} className='text-black' />
                <p className='text-xs m-1 font-semibold'>{car.fuelType}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                <GiGearStickPattern size={20} className='text-black' />
                <p className='text-xs m-1 font-semibold'>{car.transmissionType}</p>
              </div>
            </div>
            <p className='m-4 font-semibold'>₹ {car.price} /-</p>
            <button
              className='bg-red-700 p-2 border rounded-md m-2 hover:bg-green-500 text-slate-200'
              onClick={() => handleCarSelection(car.id, car.carName)}
            >
              Block
            </button>
          </motion.div>
        ))}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className='text-xl font-semibold mb-4'>Enter Booking Details</h2>
        <label className='block mb-2'>
          Pickup Date:
          <input
            type="date"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
            className='ml-2 p-2 border rounded'
          />
        </label>
        <label className='block mb-2'>
          Pickup Time:
          <input
            type="time"
            value={pickupTime}
            onChange={(e) => setPickupTime(e.target.value)}
            className='ml-2 p-2 border rounded'
          />
        </label>
        <label className='block mb-2'>
          Dropoff Date:
          <input
            type="date"
            value={dropoffDate}
            onChange={(e) => setDropoffDate(e.target.value)}
            className='ml-2 p-2 border rounded'
          />
        </label>
        <label className='block mb-4'>
          Dropoff Time:
          <input
            type="time"
            value={dropoffTime}
            onChange={(e) => setDropoffTime(e.target.value)}
            className='ml-2 p-2 border rounded'
          />
        </label>
        <button
          className='bg-green-500 p-2 border rounded-md text-white'
          onClick={handleBooking}
        >
          Confirm Booking
        </button>
      </Modal>
    </>
  );
}
