import React from 'react';
import { Link } from 'react-router-dom';
import CarList from './CarList';
import AllCar from './AllCar';

const userName = localStorage.getItem('UserName');

const AdminDashboard = () => {
  return (
    <>
    <div className="flex">
    
      {/* Navigation */}
      <nav className="bg-gray-800 w-64">
        <div className="p-4 text-white font-bold">Admin Dashboard</div>
        {/*<h1>hello{userName}</h1>*/}
        <ul className="py-4">
          <li className="p-2">
            <Link to="/block" className="text-white p-4 block hover:bg-green-600">Block Car</Link>
          </li>
          <li className="p-2">
            <Link to="/upcomingbookings" className="text-white p-4 block hover:bg-green-600">Upcomming Bookings</Link>
          </li>
          <li className="p-2">
            <Link to="/profile" className="text-white p-4 block hover:bg-green-600">Profile</Link>
          </li>
          <li className="p-2">
            <Link to="/addcar" className="text-white p-4 block hover:bg-green-600">Add new Car</Link>
          </li>
          <li className="p-2">
            <Link to="/remove" className="text-white p-4 block hover:bg-green-600">Remove Car</Link>
          </li>
          <li className="p-2">
            <Link to="/addCoupon" className="text-white p-4 block hover:bg-green-600">Add / Remove Coupon</Link>
          </li>
          {/* Add more navigation links as needed */}
        </ul>
      </nav>

      {/* Main Content */}
      <div className="flex-1 bg-gray-200 mb-3">
        {/* Content goes here */}
        <AllCar />
      </div>
     
    </div>
    
    </>
  );
}

export default AdminDashboard;
