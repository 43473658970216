import React, { useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { motion } from 'framer-motion';

const UserProfile = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userData, setUserData] = useState(null);
  const [bookingData, setBookingData] = useState([]);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    const db = getFirestore();
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('phone', '==', phoneNumber));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        setUserData(userDoc.data());
        setError(null);

        // Fetch booking details
        const bookingsCollection = collection(db, 'carBookings');
        const bookingQuery = query(bookingsCollection, where('phone', '==', phoneNumber));
        const bookingSnapshot = await getDocs(bookingQuery);
        const bookings = bookingSnapshot.docs.map(doc => doc.data());
        setBookingData(bookings);
      } else {
        setUserData(null);
        setBookingData([]);
        setError('No user found with this phone number.');
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('An error occurred while fetching user data.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-green-300 to-blue-400 p-10">
      
        
        
      {userData && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-8 bg-white shadow-xl rounded-lg p-8 max-w-lg w-full"
        >
          <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">User Profile</h2>
          <div className="space-y-2">
            <p><strong>Name:</strong> {userData.name}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <p><strong>Phone:</strong> {userData.phone}</p>
            <p><strong>DOB:</strong> {userData.dob}</p>
            <p><strong>DL No:</strong> {userData.dlNo}</p>
            <p><strong>Aadhar:</strong> {userData.aadhar}</p>
          </div>
        </motion.div>
      )}
      {bookingData.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-8 bg-white shadow-xl rounded-lg p-8 max-w-lg w-full"
        >
          <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">Booking Details</h2>
          <div className="space-y-4">
            {bookingData.map((booking, index) => (
              <div key={index} className="border p-4 rounded shadow">
                <p><strong>Booking ID:</strong> {booking.bookingId}</p>
                <p><strong>Car Name:</strong> {booking.carName}</p>
                <p><strong>Pickup Date:</strong> {booking.pickupDate}</p>
                <p><strong>Dropoff Date:</strong> {booking.dropoffDate}</p>
                <p><strong>Total Payment:</strong> ₹{booking.totalPayment}</p>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default UserProfile;
