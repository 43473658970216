// src/components/OffersSection.js
import React, { useEffect } from 'react';
import { FaCarSide, FaHome, FaCalendarAlt, FaTools } from 'react-icons/fa';
import 'aos/dist/aos.css';
import AOS from 'aos';

const WhatWeOffer = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: false, // Whether animation should happen only once
    });
  }, []);

  const slideInVariants = {
    hidden: { opacity: 0, x: '100%' },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  return (
    <div className="py-16 bg-slate-800 text-center">
      <h2 className="text-5xl font-bold mb-12 text-slate-200">What We <span className='text-green-600'>Offer</span></h2>
      <div data-aos="zoom-in" className="flex flex-wrap justify-center gap-8">
        <div className="bg-white p-6 rounded-lg shadow-lg w-72 text-center hover:ring-2 ring-green-500">
          <FaCarSide className="text-4xl text-green-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Free Airport Delivery</h3>
          <p className="text-gray-600">Get your car delivered to the airport for free.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg w-72 text-center hover:ring-2 ring-green-500">
          <FaHome className="text-4xl text-green-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Home Delivery/Pickup</h3>
          <p className="text-gray-600">Convenient home delivery and pickup services.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg w-72 text-center hover:ring-2 ring-green-500">
          <FaCalendarAlt className="text-4xl text-green-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Free Ride Reschedule</h3>
          <p className="text-gray-600">Reschedule your ride at no extra cost.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg w-72 text-center hover:ring-2 ring-green-500">
          <FaTools className="text-4xl text-green-500 mb-4 mx-auto" />
          <h3 className="text-xl font-semibold mb-2 text-gray-700">24x7 Roadside Assistance</h3>
          <p className="text-gray-600">Enjoy 24x7 roadside assistance for any emergencies.</p>
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
