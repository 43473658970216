import React from 'react'
import banner from './img/ROYAL CAR.png'

export default function Banner() {
  return (
    <div className='absolute top-0 left-0 w-full h-96 md:h-screen -z-30'>
  <img src={banner} className='w-full h-full object-cover'/>
</div>

  )
}
