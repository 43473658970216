import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { GiCarSeat, GiGearStickPattern } from 'react-icons/gi';
import { BsFuelPump } from 'react-icons/bs';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

export default function AllCar() {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchCars = async () => {
      try {
        const db = getFirestore();
        const carCollection = collection(db, 'cars');
        const snapshot = await getDocs(carCollection);
        const carData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCars(carData);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching cars:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchCars();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <motion.div
            className="w-16 h-16 border-4 border-dashed rounded-full animate-spin border-green-500"
            initial={{ rotate: 0 }}
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity }}
          />
        </div>
      ) : (
        <div className='flex flex-wrap space-x-4 space-y-4 justify-center gap-5 mt-9'>
          {cars.map(car => (
            <motion.div 
              key={car.id}
              whileTap={{ scale: 0.8 }} 
              className='group w-80 min-w-[94px] h-96 cursor-pointer rounded-lg drop-shadow-lg 
                flex flex-col items-center justify-center bg-slate-400 hover:bg-green-500'
            >
              <p className='text-lg font-semibold'>{car.carName}</p>
              <img src={car.carPhoto} className='w-3/4' alt='car' />
              
              <div className='grid grid-cols-3 w-full mt-1'>
                <div className='flex flex-row items-center m-auto'>
                  <GiCarSeat size={20} className='text-black' />
                  <p className='text-xs m-1 font-semibold'>{car.seatCapacity}</p>
                </div>
                <div className='flex flex-row items-center m-auto'>
                  <BsFuelPump size={20} className='text-black' />
                  <p className='text-xs m-1 font-semibold'>{car.fuelType}</p>
                </div>
                <div className='flex flex-row items-center m-auto'>
                  <GiGearStickPattern size={20} className='text-black' />
                  <p className='text-xs m-1 font-semibold'>{car.transmissionType}</p>
                </div>
              </div>
              <p className='m-4 font-semibold'>₹ {car.price} /-</p>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
}
