import React, { useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dlNo, setDlNo] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [error, setError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();

    const clearForm = () => {
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setDlNo('');
        setPhone('');
        setDob('');
        setAadhar('');
        setError('Registration Successful');
    };

    const handleLogin = () => {
        navigate('/loginsignup');
    };

    const GotoT_C = () => {
        navigate('/terms');
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSignup = async (event) => {
        event.preventDefault();
        setError('');

        if (!isChecked) {
            setError('You must agree to the terms and conditions');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match!');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await updateProfile(user, { displayName: name });
            await setDoc(doc(db, 'users', user.uid), {
                name,
                email,
                dlNo,
                phone,
                dob,
                aadhar,
            });

            console.log('User registered successfully:', user);
            clearForm();
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                setError('The email address is already in use.');
            } else if (error.code === 'auth/invalid-email') {
                setError('The email address is not valid.');
            } else if (error.code === 'auth/weak-password') {
                setError('The password is too weak.');
            } else {
                setError('An error occurred during registration.');
            }
            console.error('Error registering user:', error);
        }
    };

    return (
        <form onSubmit={handleSignup} className="m-auto w-1/2 mt-10 bg-slate-200 p-8 border border-green-500 rounded-lg shadow-md">
            <h2 className="text-4xl mb-6 font-semibold text-center text-green-600">Signup</h2>
            {error && <p className="mb-4 text-red-500">{error}</p>}
            <div className="mb-4">
                <label className="block text-gray-700">Name<sup className=' text-red-600'>*</sup></label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Email<sup className=' text-red-600'>*</sup></label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Password<sup className=' text-red-600'>*</sup></label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700">Confirm Password<sup className=' text-red-600'>*</sup></label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    required
                />
            </div>
            <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                    <label className="block text-gray-700">DL No<sup className=' text-red-600'>*</sup></label>
                    <input
                        type="text"
                        value={dlNo}
                        onChange={(e) => setDlNo(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
                <div className="flex-1">
                    <label className="block text-gray-700">Phone No.<sup className=' text-red-600'>*</sup></label>
                    <input
                        type="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
            </div>
            <div className="mb-4 flex space-x-4">
                <div className="flex-1">
                    <label className="block text-gray-700">DOB<sup className=' text-red-600'>*</sup></label>
                    <input
                        type="date"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
                <div className="flex-1">
                    <label className="block text-gray-700">Aadhar Card No<sup className=' text-red-600'>*</sup></label>
                    <input
                        type="text"
                        value={aadhar}
                        onChange={(e) => setAadhar(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>
            </div>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className='mx-2 mb-5'
                />
                <p className=' inline-flex gap-1'>I agree to the <p className='text-green-600 cursor-pointer' onClick={GotoT_C}> terms and conditions</p></p>
            </label>
            <button type="submit" className="w-full py-2 px-4 bg-green-700 text-white rounded-lg hover:bg-green-500">Signup</button>
            <p className='m-3 text-center'>Already have an account? <span className='cursor-pointer text-green-600' onClick={handleLogin}>Login here</span></p>
        </form>
    );
};

export default SignUp;
