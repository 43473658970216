import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import jsPDF from 'jspdf';
import { getFirestore, doc as firestoreDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function Success() {
  const location = useLocation();
  const navigate = useNavigate();
  const paymentInfo = queryString.parse(location.search);
  const Username = localStorage.getItem('userName');
  const Email = localStorage.getItem('userEmail');
  const carname = localStorage.getItem('carname');
  const pickupDate = localStorage.getItem('pickupdate');
  const dropDate = localStorage.getItem('dropdate');
  const picktime = localStorage.getItem('pickuptime');
  const droptime = localStorage.getItem('droptime');
  const phone = localStorage.getItem('phoneNumber');

  console.log('Payment Info:', paymentInfo); // Debugging statement

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        console.log('User is not authenticated, redirecting to home'); // Debugging statement
        navigate('/');
      }
    });
  }, [navigate]);

  const handleDownloadInvoice = async () => {
    const pdfDoc = new jsPDF();

    // Add green border at the top
    pdfDoc.setFillColor(0, 128, 0); // Green color
    pdfDoc.rect(0, 0, pdfDoc.internal.pageSize.getWidth(), 10, 'F');

    pdfDoc.setFontSize(16);
    pdfDoc.setTextColor(0, 128, 0);
    pdfDoc.text('Invoice', pdfDoc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
    
    // Add invoice number in the top right corner
    const invoiceNumber = `INV-${paymentInfo.txn_id}`;
    pdfDoc.setFontSize(12);
    pdfDoc.setTextColor(0, 0, 0);
    pdfDoc.text(`Invoice No: ${invoiceNumber}`, pdfDoc.internal.pageSize.getWidth() - 60, 10);

    // Add address below the title
    pdfDoc.setFontSize(10);
    pdfDoc.text('Royal Car Rentals', 10, 30);
    pdfDoc.text('123 Main Street', 10, 35);
    pdfDoc.text('Bhubaneswar, Odisha, India', 10, 40);
    pdfDoc.text('Phone: +91 12345 67890', 10, 45);
    pdfDoc.text('Email: info@royalcar.co.in', 10, 50);

    pdfDoc.setFontSize(12);
    pdfDoc.setTextColor(0, 0, 0);

    pdfDoc.text(`Name: ${Username}`, 10, 60);
    pdfDoc.text(`Email: ${Email}`, 10, 70);
    pdfDoc.text(`Phone No: ${phone}`, 10, 80);
    pdfDoc.text(`Car Name: ${carname}`, 10, 90);
    pdfDoc.text(`Amount: Rs ${paymentInfo.amount}`, 10, 100);
    pdfDoc.text(`Transaction ID: ${paymentInfo.txn_id}`, 10, 110);
    pdfDoc.text(`UTR: ${paymentInfo.utr || 'N/A'}`, 10, 120); // Default to 'N/A' if undefined
    pdfDoc.text(`Pickup Date: ${pickupDate}`, 10, 130);
    pdfDoc.text(`Drop Date: ${dropDate}`, 10, 140);
    pdfDoc.text(`Pickup Time: ${picktime}`, 10, 150);
    pdfDoc.text(`Drop Time: ${droptime}`, 10, 160);
    pdfDoc.text(`Booking Date: ${paymentInfo.date}`, 10, 170);

    // Add digital signature at the bottom
    const pageHeight = pdfDoc.internal.pageSize.getHeight();
    pdfDoc.setFontSize(12);
    pdfDoc.text('Authorized Signature:', 10, pageHeight - 40);

    // Here you can add an image for the digital signature if you have one
    // pdfDoc.addImage(signatureImage, 'PNG', 50, pageHeight - 50, 40, 20);

    // For demonstration purposes, we will just draw a line
    pdfDoc.line(10, pageHeight - 35, 60, pageHeight - 35);

    // Add green border at the bottom
    pdfDoc.setFillColor(0, 128, 0); // Green color
    pdfDoc.rect(0, pageHeight - 10, pdfDoc.internal.pageSize.getWidth(), 10, 'F');

    const pdfBlob = pdfDoc.output('blob');

    // Get authenticated user
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.log('User is not authenticated'); // Debugging statement
      navigate('/');
      return;
    }

    // Upload PDF to Firebase Storage
    const storage = getStorage();
    const storageRef = ref(storage, `invoices/${phone}/${paymentInfo.txn_id}.pdf`);

    await uploadBytes(storageRef, pdfBlob);
    const downloadURL = await getDownloadURL(storageRef);

    // Save download URL and metadata to Firestore
    const db = getFirestore();
    const invoiceData = {
      name: Username || 'N/A',
      email: Email || 'N/A',
      phone: phone || 'N/A',
      carName: carname || 'N/A',
      amount: paymentInfo.amount || 0,
      transactionId: paymentInfo.txn_id || 'N/A',
      utr: paymentInfo.utr || 'N/A', // Default to 'N/A' if undefined
      pickupDate: pickupDate || 'N/A',
      dropDate: dropDate || 'N/A',
      pickupTime: picktime || 'N/A',
      dropTime: droptime || 'N/A',
      bookingDate: paymentInfo.date || 'N/A',
      invoiceUrl: downloadURL || 'N/A'
    };

    await setDoc(firestoreDoc(db, 'invoices', phone), invoiceData);

    pdfDoc.save(`${paymentInfo.txn_id}.pdf`);
  };

  return (
    <div className="bg-slate-200 p-8 border border-green-500 rounded-lg shadow-md w-1/2 sm:w-2/5 m-auto mt-10">
      <h2 className="mb-6 font-semibold text-center text-green-600 text-3xl">Payment Successful</h2>
      <div className="mb-4">
        <p><strong>Name:</strong> {Username}</p>
        <p><strong>Email:</strong> {Email}</p>
        <p><strong>Phone:</strong> {phone}</p>
        <p><strong>Car Name:</strong> {carname}</p>
        <p><strong>Amount:</strong> Rs {paymentInfo.amount}</p>
        <p><strong>Transaction ID:</strong> {paymentInfo.txn_id}</p>
        <p><strong>Pickup Date:</strong> {pickupDate}</p>
        <p><strong>Drop Date:</strong> {dropDate}</p>
        <p><strong>Pickup Time:</strong> {picktime}</p>
        <p><strong>Drop Time:</strong> {droptime}</p>
        <p><strong>Booking Date:</strong> {paymentInfo.date}</p>
      </div>
      <button 
        onClick={handleDownloadInvoice} 
        className="w-full py-2 px-4 bg-green-700 text-white rounded-lg hover:bg-green-500"
      >
        Download Invoice
      </button>
    </div>
  );
}
