import React from 'react';

const blogs = [
  {
    id: 1,
    title: 'Car Hire Online',
    date: '30 July 2020',
    author: 'Gyanaranjan Acharya',
    comments: 12,
    summary: 'If you are looking for Car rental in Bhubaneswar online then Royal Car will be the best option for you. Royal Car is a Self Drive car rental company in Bhubaneswar is leading the way to deliver your favourite car at your doorstep .....',
    imageUrl: 'path/to/your/image1.jpg', // Update with your image path
  },
  {
    id: 2,
    title: 'Car Rental Services in Bhubaneswar',
    date: '20 Jan 2020',
    author: 'Gyanaranjan Acharya',
    comments: 12,
    summary: 'Royal Car is an emerging Car rental Services in Bhubaneswar with an innovative way of servicing the requirements of the ever growing car rental industry. A dream come true, it was finally launched with its services in Bhubaneswar by a team of efficient members who are fascinated about cars......',
    imageUrl: 'path/to/your/image2.jpg', // Update with your image path
  },
  {
    id: 3,
    title: 'Self Drive Cars in Bhubaneswar',
    date: '10 Oct 2020',
    author: 'Ayushman Dash',
    comments: 12,
    summary: 'Royal Car is probably the best company offering magnificent Self drive Cars in Bhubaneswar. We offer such Car Rental Services so that our clients gets 100 percent satisfaction and fully enjoy the services............',
    imageUrl: 'path/to/your/image3.jpg', // Update with your image path
  },
];

const Blog = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-green-600 text-white py-6">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold">Royal Car Blog</h1>
          <p className="mt-2">Self Drive Car Rental in Bhubaneswar</p>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {blogs.map(blog => (
            <article key={blog.id} className="bg-white p-6 rounded-lg shadow-md">
              <img src={blog.imageUrl} alt={blog.title} className="w-full h-40 object-cover rounded-t-lg" />
              <div className="p-4">
                <p className="text-gray-600 mb-2">{blog.date}</p>
                <h3 className="text-xl font-bold mb-2">{blog.title}</h3>
                <p className="mb-4">{blog.summary}</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="text-gray-700">By {blog.author}</span>
                    <span className="text-gray-500 ml-4">{blog.comments} Comments</span>
                  </div>
                  <button className="text-green-600 hover:text-green-800 font-semibold">Read More</button>
                </div>
              </div>
            </article>
          ))}
        </div>
      </main>

      <footer className="bg-green-600 text-white py-6 mt-10">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Royal Car. All rights reserved.</p>
          <p>Contact us: [contact email] | [contact phone number]</p>
        </div>
      </footer>
    </div>
  );
};

export default Blog;
