import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase'; // Adjust the import path accordingly
import { doc, getDocs ,collection,where,query } from 'firebase/firestore';

export default function PaymentPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { car, pickupdate, dropdate } = location.state || {};
    const [totalDays, setTotalDays] = useState(0);
    const [totalPayment, setTotalPayment] = useState(0);
    const [user, setUser] = useState(null);
    const [coupon, setCoupon] = useState('');
    const [discount, setDiscount] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const securityDeposit = 2000; // Fixed security deposit amount

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log('User is logged in:', user);
                setUser(user);
            } else {
                console.log('No user logged in, redirecting to login');
                navigate('/login'); // Redirect to login page if not authenticated
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        if (pickupdate && dropdate) {
            const pickupDate = new Date(pickupdate);
            const dropDate = new Date(dropdate);
            const diffTime = Math.abs(dropDate - pickupDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            setTotalDays(diffDays);
            setTotalPayment(diffDays * car.price); 
        }
    }, [pickupdate, dropdate, car]);

    const handlePayment = async () => {
        if (!user) {
            console.log('User is not authenticated, redirecting to login');
            navigate('/login');
            return;
        }

        try {
            const paymentDetails = {
                carId: car.id,
                carName: car.carName,
                totalDays,
                totalPayment,
                securityDeposit,
                pickupdate,
                dropdate,
                coupon,
                discount
            };
            localStorage.setItem('carname', car.carName);

            const response = await axios.post('https://royalcar.co.in/payment/', { ...paymentDetails });
            window.location.href = response.data; // Assuming response.data contains the redirect URL
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleApplyCoupon = async () => {
        setError('');
        setSuccess('');
    
        if (!coupon) {
            setError('Please enter a coupon code');
            return;
        }
    
        try {
            //console.log('Checking coupon:', coupon);
            const couponsRef = collection(db, 'coupons');
            const q = query(couponsRef, where('code', '==', coupon));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                const couponData = querySnapshot.docs[0].data();
                //console.log('Coupon data:', couponData);
                setDiscount(couponData.discount);
                const newTotalPayment = totalDays * car.price * (1 - couponData.discount / 100);
                setTotalPayment(newTotalPayment);
                setSuccess('Congrats!! Coupon applied successfully!');
            } else {
                setError('Invalid coupon code');
            }
        } catch (error) {
            console.error('Error applying coupon:', error);
            setError('Error applying coupon: ' + error.message);
        }
    };

    if (!car) {
        return <p>No car selected.</p>;
    }
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg m-10">
                <h1 className="text-2xl font-semibold mb-4">Payment Details</h1>
                <div className="border p-4 rounded-lg shadow-lg bg-gray-50 mb-4">
                    <h2 className="text-lg font-bold mb-2">Selected Car</h2>
                    <p><strong>Car Name:</strong> {car.carName}</p>
                    <p><strong>Seat Capacity:</strong> {car.seatCapacity}</p>
                    <p><strong>Fuel Type:</strong> {car.fuelType}</p>
                    <p><strong>Transmission Type:</strong> {car.transmissionType}</p>
                    <img src={car.carPhoto} alt={car.carName} className="w-auto h-auto object-cover mt-2 rounded-lg items-center" />
                </div>
                <div className="border p-4 rounded-lg shadow-lg bg-gray-50">
                    <h2 className="text-lg font-bold mb-2">Billing Details</h2>
                    <p><strong>Pickup Date:</strong> {pickupdate}</p>
                    <p><strong>Dropoff Date:</strong> {dropdate}</p>
                    <p><strong>Total Days:</strong> {totalDays}</p>
                    <p><strong>Per Day Cost:</strong> ₹{car.price}</p>
                    <p><strong>Total Payment:</strong> ₹{totalPayment}</p>
                    <p><strong>Security Deposit:</strong> ₹{securityDeposit}</p>
                    <p className="text-sm text-gray-600">(Security deposit is not included in the total payment and will be refunded after the rental period)</p>
                </div>
                <div className="border p-4 rounded-lg shadow-lg bg-gray-50 mb-4">
                    <h2 className="text-lg font-bold mb-2">Apply Coupon</h2>
                    <input
                        type="text"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg mb-2"
                        placeholder="Enter coupon code"
                    />
                    <button onClick={handleApplyCoupon} className="bg-green-700 text-white p-2 rounded-md hover:bg-green-500 w-full">Apply Coupon</button>
                    {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
                    {success && <p className="mt-4 text-green-600 text-center">{success}</p>}
                </div>
                <button onClick={handlePayment} className="bg-green-700 text-white p-2 rounded-md mt-4 hover:bg-green-500 w-full">Proceed to Pay</button>
            </div>
        </div>
    );
}
