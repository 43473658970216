import React, { useState, useEffect } from 'react';
import { categories, city } from './utils/data';
import { useNavigate } from 'react-router-dom';

export default function InputForm() {
  const [minDate, setMinDate] = useState('');
  const [cartype, setCartype] = useState('');
  const [selectcity, setselectCity] = useState('');
  const [pickupdate, setPickupDate] = useState('');
  const [dropdate, setDropdate] = useState('');
  const [pickTime, setPickTime] = useState('');
  const [dropTime, setDropTime] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateDates = () => {
    const pickupDateTime = new Date(`${pickupdate}T${pickTime}`);
    const dropoffDateTime = new Date(`${dropdate}T${dropTime}`);

    if (dropoffDateTime - pickupDateTime < 24 * 60 * 60 * 1000) {
      setError('Drop-off date and time must be at least 24 hours after pickup.');
    } else {
      setError('');
    }
  };

  const handleDropTime = () => {
    validateDates();
  };

  const handlePickupDateChange = (event) => {
    const selectedDate = event.target.value;
    setPickupDate(selectedDate);
    setDropdate('');
  };

  const getMinDropoffDate = () => {
    if (!pickupdate) return '';
    const date = new Date(pickupdate);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  const formSearch = () => {
    navigate('/result', {
      state: { cartype, selectcity, pickupdate, dropdate, pickTime, dropTime }
    });
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setMinDate(today);
  }, []);

  // Function to check if the current browser is Safari
  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1;
  };

  return (
    <div className="flex flex-col items-center justify-center w-auto">
      <div className="text-center m-10 w-full lg:h-auto lg:w-auto text-4xl font-semibold text-white">
        Search for the Vehicles
      </div>
      <div className="w-full lg:w-[30rem] flex items-center justify-center">
        <div className="w-full md:w-[75%] border border-green-800 rounded-lg p-4 flex flex-col items-center justify-center gap-4 bg-slate-300 bg-opacity-80">
          <div className="w-full flex flex-col py-2 border-gray-300 gap-1">
            <label>
              Select Car Type<sup className="text-red-600">*</sup>
            </label>
            <select
              required
              onChange={(e) => setCartype(e.target.value)}
              className="outline-none w-full text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer"
            >
              <option value="other" className="bg-white">
                Select Car Type
              </option>
              {categories &&
                categories.map((item) => (
                  <option
                    key={item.id}
                    className="text-base border-0 outline-none capitalize bg-white text-headingColor"
                    value={item.urlParamName}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="w-full flex flex-col gap-1">
            <label>Select City</label>
            <select
              onChange={(e) => setselectCity(e.target.value)}
              className="outline-none w-full text-base border-2 border-gray-200 p-2 rounded-md cursor-pointer"
            >
              <option value="other" className="bg-white">
                City
              </option>
              {city &&
                city.map((item) => (
                  <option
                    key={item.id}
                    className="text-base border-0 outline-none capitalize bg-white text-headingColor"
                    value={item.urlParamName}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="w-full flex flex-col md:flex-row items-center gap-3">
            <div className="w-full md:w-1/2 py-2 border-b border-gray-300 items-center gap-1">
              <label>Pick-Up Date</label>
              <input
                type={isSafari() ? "text" : "date"}
                required
                min={minDate}
                value={pickupdate}
                onChange={handlePickupDateChange}
                placeholder="YYYY-MM-DD"
                className="w-full h-full text-lg font-semibold bg-transparent outline-none border-none placeholder-gray text-textColor"
              />
            </div>
            <div className="w-full md:w-1/2 py-2 border-b border-gray-300 items-center gap-2">
              <label>Drop Date</label>
              <input
                type={isSafari() ? "text" : "date"}
                required
                min={getMinDropoffDate()}
                value={dropdate}
                onChange={(e) => setDropdate(e.target.value)}
                placeholder="YYYY-MM-DD"
                className="w-full h-full text-lg font-semibold bg-transparent outline-none border-none placeholder-gray text-textColor"
              />
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row items-center gap-3">
            <div className="w-full md:w-1/2 py-2 border-b border-gray-300 items-center gap-1">
              <label>Pick-Up Time</label>
              <input
                type={isSafari() ? "text" : "time"}
                required
                value={pickTime}
                onChange={(e) => setPickTime(e.target.value)}
                placeholder="HH:MM"
                className="w-full h-full text-lg font-semibold bg-transparent outline-none border-none placeholder-gray text-textColor"
              />
            </div>
            <div className="w-full md:w-1/2 py-2 border-b border-gray-300 items-center gap-2">
              <label>Drop Time</label>
              <input
                type={isSafari() ? "text" : "time"}
                required
                value={dropTime}
                onChange={(e) => {
                  setDropTime(e.target.value);
                  handleDropTime();
                }}
                placeholder="HH:MM"
                className="w-full h-full text-lg font-semibold bg-transparent outline-none border-none placeholder-gray text-textColor"
              />
            </div>
            {error && <p className="text-red-500 text-xs italic">{error}</p>}
          </div>
          <div className="flex items-center w-full">
            <button
              type="button"
              className="ml-0 md:ml-auto w-full md:w-auto border-none outline-none px-12 py-2 rounded-lg text-lg bg-gradient-to-br from-green-700 to-green-500 text-green-100 font-semibold"
              onClick={formSearch}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
