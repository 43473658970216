// src/routes.js

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Navbar from './components/Navbar';
import LoginSignup from './components/LoginSignup';
import AboutUs from './components/AboutUs';
import CarList from './components/CarList';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />, // Main application component or layout
    children: [
      { path: "/", element: <LoginSignup /> }, // Home component
      { path: "loginsignup", element: <LoginSignup /> }, // Login/Signup component
      { path: "/about"},
      { path: "/terms"},
      { path: "/addcar"},
      { path: "/result"},
      { path: "/block"},
      { path: "/profile"},
      { path: "/upcomingbookings"},
      { path: "/payment"},
      { path: "/admin"},
      { path: "/signup"},
      { path: "/policy"},
      { path: "/remove"},
      { path: "/paymentnew"},
      { path: "/success"},
      { path: "/addCoupon"},
      { path: "/privacy"},
      { path: "/blog"},
      { path: "/User"}
    ],
  },
]);

export default router;
