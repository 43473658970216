import React,{useEffect} from 'react';
import AOS from 'aos';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import photo from './img/DSC_3851.JPG';
import 'aos/dist/aos.css';

const TestimonialSection = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <div data-aos="fade-up"
      
      className="relative bg-gray-200 py-12"
    >
      <div className="max-w-6xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden md:flex">
          {/* Testimonial Content */}
          <div className="md:w-1/2 px-4 py-6 md:p-8">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-green-600">What Our Customers Say</h2>
            <blockquote className="italic text-gray-700 mb-4">
              "Renting from Royal Car was an absolute pleasure. The process was seamless from start to finish. The car was clean and in excellent condition."
            </blockquote>
            <p className="text-gray-600">- Shahnawaz Alam, Happy Customer</p>
          </div>

          {/* Testimonial Photo */}
          <div 
          
          className="md:w-1/2 relative">
            <div className="absolute inset-0 bg-cover bg-center z-0 bg-gray-800"></div>
            < motion.div 
            whileInView={{scale:0.9}} 
            className="relative z-10 md:p-8 flex items-center justify-center">
              <img src={photo} alt="Customer" className="rounded-full border-4 border-white shadow-lg h-60" />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
