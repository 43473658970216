import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Adjust the import path based on your project structure
import { collection, addDoc, getDocs, deleteDoc, doc } from 'firebase/firestore';

const Coupon = () => {
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'coupons'));
      const couponsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCoupons(couponsList);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  const handleAddCoupon = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!couponCode || !discount) {
      setError('Please fill in all fields');
      return;
    }

    if (isNaN(discount) || discount <= 0 || discount > 100) {
      setError('Discount must be a number between 1 and 100');
      return;
    }

    try {
      await addDoc(collection(db, 'coupons'), {
        code: couponCode,
        discount: parseInt(discount, 10),
      });
      setSuccess('Coupon added successfully');
      setCouponCode('');
      setDiscount('');
      fetchCoupons(); // Refresh the coupons list
    } catch (error) {
      setError('Error adding coupon: ' + error.message);
    }
  };

  const handleDeleteCoupon = async (couponId) => {
    try {
      await deleteDoc(doc(db, 'coupons', couponId));
      setSuccess('Coupon deleted successfully');
      fetchCoupons(); // Refresh the coupons list
    } catch (error) {
      setError('Error deleting coupon: ' + error.message);
    }
  };

  return (
    <div className="bg-gray-100 p-8 border border-green-500 rounded-lg shadow-md w-1/2 sm:w-2/5 m-auto mt-10">
      <h2 className="mb-6 font-semibold text-center text-green-600 text-3xl">Add New Coupon</h2>
      <form onSubmit={handleAddCoupon}>
        <div className="mb-4">
          <label className="block text-gray-700">Coupon Code:</label>
          <input
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Discount (%):</label>
          <input
            type="number"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
        {success && <p className="mt-4 text-green-600 text-center">{success}</p>}
        <button type="submit" className="w-full py-2 px-4 bg-green-700 text-white rounded-lg hover:bg-green-500">
          Add Coupon
        </button>
      </form>
      <h2 className="mt-8 font-semibold text-center text-green-600 text-2xl">Existing Coupons</h2>
      {coupons.length > 0 ? (
        <ul className="mt-4">
          {coupons.map(coupon => (
            <li key={coupon.id} className="flex justify-between items-center bg-white p-4 mb-2 rounded-lg shadow-sm">
              <span>{coupon.code} - {coupon.discount}%</span>
              <button
                onClick={() => handleDeleteCoupon(coupon.id)}
                className="py-1 px-3 bg-red-600 text-white rounded-lg hover:bg-red-400"
              >
                Delete
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p className="mt-4 text-center text-gray-700">No coupons found.</p>
      )}
    </div>
  );
};

export default Coupon;
