import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { GiCarSeat, GiGearStickPattern } from 'react-icons/gi';
import { BsFuelPump } from 'react-icons/bs';
import { getFirestore, collection, getDocs, query, where,deleteDoc,doc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path to your firebase.js file
import AdminDashboard from './AdminDashboard';

export default function UpcomingBookings() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const db = getFirestore();
        const bookingCollection = collection(db, 'carBookings');
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

        const q = query(bookingCollection, where('pickupDate', '>=', todayString));
        const bookingSnapshot = await getDocs(q);
        const bookingData = bookingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        setBookings(bookingData);
      } catch (error) {
        //console.error('Error fetching bookings:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const cancelBooking = async (bookingId) => {
    console.log(bookingId)
    try {
      //console.log('Attempting to cancel booking with ID:', bookingId); // Debugging log

      if (typeof bookingId !== 'string') {
        throw new Error(`Booking ID must be a string. Received: ${typeof bookingId}`);
      }

      const db = getFirestore();
      await deleteDoc(doc(db, 'carBookings', bookingId));
      setBookings(bookings.filter(booking => booking.id !== bookingId));
      //console.log('Successfully cancelled booking with ID:', bookingId); // Debugging log
    } catch (error) {
      //console.error('Error cancelling booking:', error);
      setError(error);
    }
  };

  if (loading) {
    return <p>Loading bookings...</p>;
  }
  

  if (error) {
    return <p>Error loading bookings: {error.message}</p>;
  }

  return (
    
    <div>
      <h1 className="text-2xl font-semibold m-4 text-center">Upcoming Bookings</h1>
      {bookings.length === 0 ? (
        <p className='m-5 text-red-600'>No upcoming bookings found.</p>
      ) : (
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mx-40 mt-10 '>
        {bookings.map(booking => (
          <motion.div 
            key={booking.id}
            whileTap={{ scale: 0.8 }} 
            className='group w-80 min-w-[94px] h-max cursor-pointer rounded-lg drop-shadow-lg 
              flex flex-col items-center justify-center bg-slate-400 hover:bg-green-500'
          >
            <p className='text-lg font-semibold mt-3'>{booking.carName}</p>
            
            
            <div className='grid grid-row-4 w-full mt-1'>
            <div className='flex flex-row items-center m-auto'>
                
                <p className='m-2 font-semibold'>Coustomer Name: {booking.name}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                
                <p className='m-2 font-semibold'>Coustomer phone: {booking.phone}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                
                <p className='m-2 font-semibold'>Pickup Date: {booking.pickupDate}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                
                <p className='m-2 font-semibold'>PickUp Time: {booking.pickupTime}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
                
                <p className='m-2 font-semibold'>Drop Date: {booking.dropoffDate}</p>
              </div>
              <div className='flex flex-row items-center m-auto'>
              <p className='m-2 font-semibold'>Drop Time: {booking.dropoffTime}</p>
              </div>
            </div>
            
            <button 
              className='bg-green-700 p-2 border rounded-md m-2 hover:bg-green-500 text-slate-200'
              onClick={() => cancelBooking(booking.id)}>
              Cancle Booking
            </button>
          </motion.div>
        ))}
      </div>
      )}
    </div>
  );
}
