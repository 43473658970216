// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNhy9eGipWpgs2sEauA_EOrj_8pycSNcg",
  authDomain: "royalcar-f7d62.firebaseapp.com",
  databaseURL: "https://royalcar-f7d62-default-rtdb.firebaseio.com",
  projectId: "royalcar-f7d62",
  storageBucket: "royalcar-f7d62.appspot.com",
  messagingSenderId: "73892607505",
  appId: "1:73892607505:web:1d4db3dcb4c2bca8c4a3b0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
const storage = getStorage(app);
export {app,auth,db,storage}