import React, { useState } from 'react';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const CarForm = () => {
  const [carType, setCarType] = useState('');
  const [carName, setCarName] = useState('');
  const [carPhoto, setCarPhoto] = useState(null);
  const [seatCapacity, setSeatCapacity] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [transmissionType, setTransmissionType] = useState('');
  const [price, setPrice] = useState('');

  
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let carPhotoURL = '';
      if (carPhoto) {
        const storageRef = ref(storage, `carPhotos/${carPhoto.name}`);
        const snapshot = await uploadBytes(storageRef, carPhoto);
        carPhotoURL = await getDownloadURL(snapshot.ref);
      }

      const docRef = await addDoc(collection(db, 'cars'), {
        carType,
        carName,
        seatCapacity,
        fuelType,
        transmissionType,
        price,
        carPhoto: carPhotoURL,
      });

      console.log('Document written with ID: ', docRef.id);

      // Reset form fields
      setCarType('');
      setCarName('')
      setCarPhoto(null);
      setSeatCapacity('');
      setFuelType('');
      setTransmissionType('');
      setPrice('');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-5 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-5 text-center">Car Details Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="carType">
            Car Type
          </label>
          <input
            type="text"
            id="carType"
            value={carType}
            onChange={(e) => setCarType(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="carName">
            Car Name
          </label>
          <input
            type="text"
            id="carName"
            value={carName}
            onChange={(e) => setCarName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="carPhoto">
            Car Photo
          </label>
          <input
            type="file"
            id="carPhoto"
            accept="image/*"
            onChange={(e) => setCarPhoto(e.target.files[0])}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="seatCapacity">
            Seat Capacity
          </label>
          <input
            type="number"
            id="seatCapacity"
            value={seatCapacity}
            onChange={(e) => setSeatCapacity(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fuelType">
            Fuel Type
          </label>
          <select
            id="fuelType"
            value={fuelType}
            onChange={(e) => setFuelType(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select Fuel Type</option>
            <option value="Petrol">Petrol</option>
            <option value="Diesel">Diesel</option>
            <option value="Electric">Electric</option>
            <option value="CNG">CNG</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="transmissionType">
            Transmission Type
          </label>
          <select
            id="transmissionType"
            value={transmissionType}
            onChange={(e) => setTransmissionType(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          >
            <option value="">Select Transmission Type</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="price">
            Price
          </label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            required
          />
        </div>
        <div className="flex justify-center w-auto">
          <button
            type="submit"
            className="bg-green-700 text-white px-4 py-2 rounded-md hover:bg-green-500"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CarForm;
