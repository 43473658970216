import React from 'react';

const CancellationPolicyPage = () => {
  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-4">Cancellation Policy</h1>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Introduction</h2>
        <p className="text-gray-700">
          Welcome to our cancellation policy page. We understand that plans can change, and we aim to provide clear guidelines on how cancellations are handled.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Key Points Summary</h2>
        <ul className="list-disc list-inside text-gray-700">
          <li>Cancellations must be made at least 24 hours in advance.</li>
          <li>Cancellation fees may apply based on the booking type.</li>
          <li>Refunds are processed within 5-7 business days.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Detailed Policy</h2>

        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Booking Cancellation</h3>
          <p className="text-gray-700">
            You can cancel your booking by contacting our customer service team or through our online portal. Please ensure to cancel at least 24 hours before your scheduled booking.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Cancellation Fees</h3>
          <p className="text-gray-700">
            Cancellation fees may apply depending on the type of booking and cancellation timeframe. Non-refundable deposits are subject to full forfeiture upon cancellation.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Refund Process</h3>
          <p className="text-gray-700">
            Refunds for cancellations are processed within 5-7 business days. The amount refunded may vary based on any applicable cancellation fees.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Exceptions and Special Cases</h3>
          <p className="text-gray-700">
            In cases of force majeure or emergencies, exceptions to our cancellation policy may apply. Contact our customer support for further assistance.
          </p>
        </div>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Contact Information</h2>
        <p className="text-gray-700">
          For any questions or assistance regarding cancellations, please contact our customer support team at <a href="tel:+123456789">+1 (234) 567-89</a> or email us at <a href="mailto:support@example.com">support@example.com</a>.
        </p>
      </div>
      
      <div className="text-center text-gray-600 mt-8">
        <p>For full details, please refer to our <a href="/terms-of-service">Terms of Service</a>.</p>
      </div>
    </div>
  );
};

export default CancellationPolicyPage;
