import React from 'react';

const Privacy = () => {
  return (
    <div className="p-8 bg-white shadow-md rounded-lg max-w-3xl mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy for Royal Car</h1>
      <p><strong>Effective Date:</strong> 10 May 2024</p>
      
      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p>
          Welcome to Royal Car. We value your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website, www.royalcar.co.in, and use our services.
        </p>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
        <p>We may collect and process the following types of information about you:</p>
        <ul className="list-disc pl-5">
          <li><strong>Personal Information:</strong> Name, email address, phone number, date of birth, address, driver's license number, and payment information.</li>
          <li><strong>Booking Information:</strong> Details of your bookings, including car details, rental dates, and payment amounts.</li>
          <li><strong>Technical Information:</strong> IP address, browser type, operating system, and other usage details collected automatically through cookies and similar technologies.</li>
          <li><strong>Communication Information:</strong> Records of your communications with us, including emails and phone calls.</li>
        </ul>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <ul className="list-disc pl-5">
          <li>To provide and manage your bookings and rentals.</li>
          <li>To process payments and manage transactions.</li>
          <li>To communicate with you about your bookings and our services.</li>
          <li>To improve our website and services.</li>
          <li>To comply with legal obligations and protect our legal rights.</li>
        </ul>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">4. Sharing Your Information</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:</p>
        <ul className="list-disc pl-5">
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website, conducting our business, or servicing you.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
        <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">6. Your Rights</h2>
        <p>You have the following rights regarding your personal information:</p>
        <ul className="list-disc pl-5">
          <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
          <li><strong>Correction:</strong> You can request that we correct any inaccuracies in your personal information.</li>
          <li><strong>Deletion:</strong> You can request that we delete your personal information under certain circumstances.</li>
          <li><strong>Objection:</strong> You can object to the processing of your personal information under certain circumstances.</li>
        </ul>
        <p>To exercise these rights, please contact us at royalcarbbsr@gmail.com.</p>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">7. Cookies</h2>
        <p>We use cookies and similar tracking technologies to track the activity on our website and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.</p>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
        <p>We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>
      </section>

      <section className="my-6">
        <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        <p>
          Royal Car<br />
          Email: royalcarbbsr@gmail.com<br />
          Phone: +91 97778 24577
        </p>
      </section>
    </div>
  );
};

export default Privacy;
