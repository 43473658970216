import React from 'react'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


export default function Display() {
  return (
    <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  className="relative h-screen"
>
  <div className="py-3 flex-1 flex relative flex-col items-start top-48 gap-6 px-4 md:items-start md:px-8 lg:px-16">
    <p className="font-bold tracking-wide text-slate-50 mt-5">
      <span className='bg-green-600 inline-block py-3 text-3xl sm:text-5xl md:text-[6rem]'>Royal Cars</span>
      <span className="text-green-500 inline-block m-3 text-xl sm:text-3xl md:text-[2.8rem]">Explore Without Limits</span>
    </p>
    <a href="tel:9777824577">
    <button
      type="button"
      className="bg-gradient-to-br from-green-700 to-green-500 text-green-100
      w-full md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-linear duration-100"
    >
      Contact Us
    </button></a>
  </div>
</motion.div>

  )
}
