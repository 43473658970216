// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from './Routes.js'; // Import the router configuration
import './index.css'; // Your CSS file

// Get the root element
const rootElement = document.getElementById('root');

// Create a root with React 18's createRoot API
const root = createRoot(rootElement);

// Render the application
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
