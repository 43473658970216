import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: true, // Whether animation should happen only once
    });

    // Check if the user is already logged in
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleSignup = () => {
    navigate('/signup');
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true); // Start loading
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      //console.log('User logged in successfully!');
      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userEmail', email);

      // Fetch user data from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const displayName = userData.name;
        const phoneNumber = userData.phone;

        //console.log('Fetched user data:', userData);  // Debugging statement
        //console.log('Phone Number:', phoneNumber);    // Debugging statement

        // Store display name and phone number in local storage
        localStorage.setItem('userName', displayName);
        localStorage.setItem('phoneNumber', phoneNumber);
      } else {
        console.log('No such document!');
      }

      navigate('/');
    } catch (error) {
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        setError('Invalid email or password.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email address.');
      } else {
        setError('An error occurred during login.');
      }
      console.error('Error logging in:', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div data-aos="zoom-in" data-aos-delay="300">
      <form onSubmit={handleLogin} className="bg-slate-200 p-8 border border-green-500 rounded-lg shadow-md w-1/2 sm:w-2/5 m-auto mt-10">
        <h2 className="mb-6 font-semibold text-center text-green-600 text-3xl">Login</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            required
          />
        </div>
        <button type="submit" className="w-full py-2 px-4 bg-green-700 text-white rounded-lg hover:bg-green-500" disabled={isLoading}>
          {isLoading ?(
            <svg className="animate-spin h-5 w-5 mr-3 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"></path>
            </svg>
          ) : (
            'Login'
          )}
        </button>
        <p className='m-3 text-center'>Don't have an account? <span className='cursor-pointer text-green-600' onClick={handleSignup}>Register here</span></p>
        {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
      </form>
    </div>
  );
};

export default Login;
