import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const Steps = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="text-center p-8">
      <h1 className="text-4xl md:text-6xl font-bold mb-8 text-slate-600">
        How To Book a <span className='text-green-600'> Car Online </span>
      </h1>
      <p className="text-lg md:text-2xl mb-8">
        Book a car online in Bhubaneshwar from Royal Car in 4 Simple steps.
      </p>
      <div className="relative flex justify-center items-center">
        <div
          data-aos="zoom-in"
          className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 relative z-10"
        >
          <div className="step text-center">
            <div className="icon text-4xl font-bold bg-green-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">01</div>
            <h3 className="text-lg font-semibold mt-2">Select City & Travel dates</h3>
          </div>
          <div className="step text-center">
            <div className="icon text-4xl font-bold bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">02</div>
            <h3 className="text-lg font-semibold mt-2">Choose Car & Delivery Mode</h3>
          </div>
          <div className="step text-center">
            <div className="icon text-4xl font-bold bg-red-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">03</div>
            <h3 className="text-lg font-semibold mt-2">Verify Yourself</h3>
          </div>
          <div className="step text-center">
            <div className="icon text-4xl font-bold bg-yellow-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">04</div>
            <h3 className="text-lg font-semibold mt-2">Make Payment</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
